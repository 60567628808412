@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
    font-family: "Inter", sans-serif;
}

.strikethrough {
    position: relative;
}

.strikethrough:before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 4px solid;
    border-radius: 999px;
    border-color: inherit;

    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg);
}

.tooltip:after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 1.625rem;
    margin-left: -0.425rem;
    border-width: 0.425rem;
    border-style: solid;
    border-color: #404040 transparent transparent transparent;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
